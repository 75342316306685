import React, {useEffect, useState} from "react";
import axios from "axios";
import styled from "styled-components";
import {ETH_EXPLORER_URL} from "../../constants";


const TableWrapper = styled.div`
  margin-top: 50px;
  overflow-x: auto;
  font-size: 0.8rem;
  border: 1px solid var(--color-secondary);
  border-radius: 7px;
  max-width: 800px;

  table {
    border-collapse: collapse;
    width: 100%;

    th,
    td {
      padding: 6px;
      text-align: center;
      border-bottom: 1px solid #ddd;
    }

    tr:nth-child(even) {
      background-color: #f2f2f2;
    }

    th {
      background-color: var(--color-secondary);
      color: white;
    }
  }

  // @media (max-width: 600px) {
  //  table {
  //    display: block;
  //    overflow-x: auto;
  //
  //    th,
  //    td {
  //      display: block;
  //      overflow-x: auto;
  //    }
  //  }
  // }
`;


const MovementsTable = () => {
    const [movements, setMovements] = useState([]);

    useEffect(() => {
        axios.get('balances/movements').then(
            res => {
                setMovements(res.data)
            },
            err => {
                console.log(err)
            }
        )
    }, []);


    return (
        <TableWrapper>
            <table>
                <thead>
                <tr>
                    <th>Block Time Stamp</th>
                    <th>Counter Party</th>
                    <th>Amount</th>
                    <th>Tx Hash</th>
                </tr>
                </thead>
                <tbody>
                {movements.map((movement, index) => (
                    <tr key={index}>
                        <td>{movement.blockTimeStamp}</td>
                        <td>{movement.counterParty}</td>
                        <td style={{color: movement.amount >= 0 ? "green" : "red"}}>
                            {movement.amount}
                        </td>
                        <td>
                            <a href={ETH_EXPLORER_URL + "/#/transaction/" + movement.txHash}
                               style={{color: "blue"}}>
                                {movement.txHash.substring(0, 5) + "..."}
                            </a>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </TableWrapper>
    );
};

export default MovementsTable;
import styled from 'styled-components';
import SetInfo from "./SetInfo";
import SetScore from "./SetScore";
import ConfirmGame from "./ConfirmGame";
import {breakpoints as bp} from '../../GlobalStyle';
import {useAuth} from "../../authContext";
import AcceptGame from "./AcceptGame";
import RejectGame from "./RejectGame";
import CancelGame from "./CancelGame";

const surfaceMap = new Map();
surfaceMap.set('ARTIFICIAL_GRASS', 'Artificial grass');
surfaceMap.set('GRASS', 'Grass');
surfaceMap.set('HARD', 'Hard');
surfaceMap.set('CLAY', 'Clay');

const IconStyle = styled.i`
  width: 20px;
`

const CardStyle = styled.div`
  background-color: white;
  min-width: 300px;
  max-width: 300px;
  margin-bottom: 20px;
  border-radius: 10px;
  overflow: hidden;
  padding: 10px;
  border: var(--color-secondary) solid 1px;
`

const TitleContainer = styled.div`
  font-size: 0.8rem;
  background-color: var(--color-secondary);
  color: white;
  border-radius: 10px;
  text-align: center;
  max-width: 200px;
  padding: 4px;
`
const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 0.8rem;
  padding: 2px;
  border-bottom: 1.5px solid #a9a9a9;
  margin-top: 10px;
`

const ScoreContainer = styled.div`
  font-size: 1.2rem;
  padding: 2px;
  border-bottom: 1.5px solid #a9a9a9;
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  padding-bottom: 7px;
`

const ActionsContainer = styled.div`
  padding: 2px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 7px;
`

const PlayersGrid = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  margin-right: 20px;
  min-height: 100%;
  color: var(--color-secondary);
  grid-row-gap: 8px;
  vertical-align: center;
`

const SetScoreContainer = styled.div`
  margin-right: 10px;
  min-height: 100%;
`

const ScoreGrid = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  color: var(--color-secondary);
  grid-row-gap: 8px;
`

function GameItem({game}) {
    const auth = useAuth();

    let bet;
    if (game.bet.amount) {
        bet = (
            <div>
                <IconStyle className="fas fa-coins"/>
                <span>{game.bet.amount}</span>
            </div>
        )
    } else {
        bet = (
            <div>
                <IconStyle className="fas fa-coins"/>
                <span>-</span>
            </div>
        )
    }

    let date;
    if (game.date) {
        date = (
            <div>
                <IconStyle className="fas fa-calendar-alt"/>
                <span>{game.date}</span>
            </div>
        )
    } else {
        date = (<div>
            <IconStyle className="fas fa-calendar-alt"/>
            <span>-</span>
        </div>)
    }


    let location;
    if (game.location) {
        location = (
            <div>
                <IconStyle className="fas fa-map-marker-alt"/>
                <span>{game.location}</span>
            </div>
        )
    } else {
        location = (<div>
            <IconStyle className="fas fa-map-marker-alt"/>
            <span>-</span>
        </div>)
    }

    let surface;
    if (game.surface) {
        surface = (
            <div>
                <IconStyle className="fas fa-ring"/>
                <span>{surfaceMap.get(game.surface.name)}</span>
            </div>
        )
    } else {
        surface = (
            <div>
                <IconStyle className="fas fa-ring"/>
                <span>-</span>
            </div>
        )
    }
    let score;
    let sets = [];
    if (game.score) {
        for (let i = 0; i < game.score.sets; i++) {
            sets.push(
                <SetScoreContainer>
                    <ScoreGrid>
                        <div>{game.score.player1[i]}</div>
                        <div>{game.score.player2[i]}</div>
                    </ScoreGrid>
                </SetScoreContainer>
            )
        }
        score = (
            <>
                {sets}
            </>
        )
    } else {
        score = <></>
    }

    let actions
    let confirm
    let reject
    let accept
    let cancel
    let setInfo
    let setScore

    if ((auth.user.id === game.createdBy && game.state === "CREATED") || game.state === "ACCEPTED") {
        cancel = <CancelGame game={game}></CancelGame>
    } else {
        cancel = <></>
    }

    if (auth.user.id !== game.createdBy && game.state === "CREATED") {
        accept = <AcceptGame game={game}></AcceptGame>
    } else {
        accept = <></>
    }

    if (auth.user.id !== game.createdBy && game.state === "CREATED") {
        reject = <RejectGame game={game}></RejectGame>
    } else {
        reject = <></>
    }

    if ((auth.user.id === game.player1.id && game.scoreState === "RESULT_SETTLED_BY_PLAYER_2") ||
        (auth.user.id === game.player2.id && game.scoreState === "RESULT_SETTLED_BY_PLAYER_1")) {
        confirm = <ConfirmGame game={game}></ConfirmGame>
    } else {
        confirm = <></>
    }

    if (game.state === "ACCEPTED") {
        setInfo = <SetInfo game={game}></SetInfo>
        setScore = <SetScore game={game}></SetScore>
    } else {
        setInfo = <></>
        setScore = <></>
    }

    if (game.state === "CONFIRMED" || game.state === "CANCELLED" || game.state === "REJECTED") {
        actions = <></>
    } else {
        actions = (
            <>
                <ActionsContainer>
                    {setInfo}
                    {setScore}
                    {accept}
                    {reject}
                    {confirm}
                    {cancel}
                </ActionsContainer>
            </>
        )
    }

    return (
        <CardStyle>
            <TitleContainer>Rated free game</TitleContainer>
            <HeaderContainer>
                {bet}
                {date}
                {location}
                {surface}
            </HeaderContainer>
            <ScoreContainer>
                <PlayersGrid>
                    <div>{game.player1.countryFlag} {game.player1.username}</div>
                    <div>{game.player2.countryFlag} {game.player2.username}</div>
                </PlayersGrid>
                {score}
            </ScoreContainer>
            {actions}
        </CardStyle>
    )
}


export default GameItem;

import React, {useEffect, useState} from "react";
import styled from "styled-components";
import axios from "axios";
import PlayerForm from "./PlayerForm";

const TableWrapper = styled.div`
  overflow-x: auto;
  font-size: 0.8rem;
  border: 1px solid var(--color-secondary);
  border-radius: 7px;
  max-width: 600px;


  table {
    border-collapse: collapse;
    width: 100%;

    th,
    td {
      padding: 6px;
      text-align: center;
      border-bottom: 1px solid #ddd;
    }

    tr:nth-child(even) {
      background-color: #f2f2f2;
    }

    th {
      background-color: var(--color-secondary);
      color: white;
    }
  }

  //@media (max-width: 600px) {
  //  table {
  //    display: block;
  //    overflow-x: auto;
  //
  //    th,
  //    td {
  //      display: block;
  //      overflow-x: auto;
  //    }
  //  }
  //}
`;

const RankingTable = () => {

    const [ranking, setRanking] = useState({"rank": {"players": []}});

    useEffect(() => {
        axios.get('rankings?name=ATR%20Ranking').then(
            res => {
                setRanking(res.data.items[0])
            },
            err => {
                console.log(err)
            }
        )
    }, []);

    return (
        <TableWrapper>
            <table>
                <thead>
                <tr>
                    <th>#</th>
                    <th></th>
                    <th>Player</th>
                    <th>Score</th>
                    <th>Form</th>
                </tr>
                </thead>
                <tbody>
                {
                    ranking.rank.players.map((player, index) => (
                        <tr key={index}>
                            <td>{player.position}</td>
                            <td width={"20px"}>{player.flag}</td>
                            <td>{player.username}</td>
                            <td>{player.rankingScore}</td>
                            <td width={"20px"}><PlayerForm text={player.form}></PlayerForm>
                            </td>
                        </tr>))
                }
                </tbody>
            </table>
        </TableWrapper>
    );
};

export default RankingTable;
import Page from "../ui/Page";
import {useAuth} from "../authContext";
import React, {useEffect, useState} from "react";
import axios from "axios";
import styled from "styled-components";
import GameItem from "../components/games/GameItem";

const GamesContainer = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
`

function Home() {
    const auth = useAuth();
    const [games, setGames] = useState([]);

    useEffect(() => {
        axios.get('games?state=COMPLETED').then(
            res => {
                setGames(res.data.items)
            },
            err => {
                console.log(err)
            }
        )
    }, [])


    return (
        <Page title={"Welcome " + auth.user.username}>
            <h3>Last games completed</h3>
            <GamesContainer>
                {games.map((game) => (
                    <GameItem game={game}/>
                ))}
            </GamesContainer>
        </Page>
    );
}

export default Home;
import styled from 'styled-components';

const StyledDiv = styled.div`
  margin-bottom: 15px;
  display: flex;
  border-bottom: #f0f0f0 1px solid;
  padding-bottom: 20px;
  align-items: center;
`;

function FieldNoLabel(props) {
    return (
        <StyledDiv>
          <div>
              {props.children}
          </div>
        </StyledDiv>
    )
}

export default FieldNoLabel;
import styled from "styled-components";
import {breakpoints as bp} from "../../GlobalStyle";
import {Button} from "../../ui/core";
import { useHistory } from "react-router-dom";

const Grid = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  height: 48px;
  align-items: stretch;
  padding: 0 24px;

  > div {
    display: flex;
    align-items: center;
  }

  button {
    white-space: nowrap;
  }

  .nav-toggle {
    pointer-events: none;
    opacity: 0;
    @media (max-width: ${bp.desktop}) {
      opacity: 1;
      pointer-events: all;
    }
  }
`;

function NoSignHeader({toggle}) {

    const history = useHistory();

    const handleLoginClick = () =>{
        let path = '/login';
        history.push(path);
    }

    const handleSignUpClick = () =>{
        let path = '/register';
        history.push(path);
    }
    return (
        <Grid>
            <div>
                <Button onClick={handleLoginClick}>Login</Button>
                <Button onClick={handleSignUpClick}>Sign Up</Button>
            </div>
        </Grid>
    );
}

export default NoSignHeader;

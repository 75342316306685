import {Button, Field, Message} from "../../ui/core";
import Select from "react-select";
import styled from "styled-components";
import FieldNoLabel from "../../ui/core/FieldNoLabel";
import axios from "axios";
import {useEffect, useState} from "react";

const RowSelect = styled.div`
  width: 100%;
  min-width: 150px;
  height: 100%;
  margin-bottom: 40px;
`

const RowLabel = styled.div`
  width: 100%;
  min-width: 150px;
  height: 100%;
  margin-bottom: 10px;
  color: var(--color-secondary-light);
  font-size: 1rem;
`

function GameForm({loading, onSubmit, opponent: opponent, setOpponent: setOpponent, bet: bet, setBet: setBet, error}) {

    const [opponents, setOpponents] = useState([])
    useEffect(() => {
        axios.get('game-opponents').then(
            res => {
                let items = []
                res.data.forEach(
                    element => items.push({'value': element, 'label': element})
                );
                setOpponents(items)
            },
            err => {
                console.log(err)
            }
        )
    }, [])


    const onChange = (e) => {
        setBet(e.target.value);
    }

    return (
        <form onSubmit={onSubmit}>
            <RowLabel>
                Opponent
            </RowLabel>
            <RowSelect>
                <Select
                    options={opponents} styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                    }),
                }}
                    className="basic-single"
                    classNamePrefix="select"
                    isSearchable="true"
                    name="color"
                    onChange={(value) => setOpponent(value.value)}
                    value={opponent.value}
                    placeholder="Select your opponent"
                    name="opponent"
                />
            </RowSelect>
            <RowLabel>
                Bet
            </RowLabel>
            <FieldNoLabel id="bet-title">
                <input
                    type="number"
                    value={bet}
                    onChange={onChange}
                    name="bet"
                    id="bet-title"
                />
            </FieldNoLabel>
            <div>
                <Button loading={loading} type="submit">
                    Save
                </Button>
            </div>
            <Message text={error} type="error"/>
        </form>
    );
}

export default GameForm;

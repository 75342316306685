import {useContext, useEffect, useState} from "react";
import Button from "../../ui/core/Button";
import AddGameModal from "../../ui/AddGameModal";
import {ToasterContext} from "../../ui/toasterCtx/ToasterContext";
import GameForm from "./GameForm";
import axios from "axios";
import {GameContext} from "./GameContext"
import {useAuth} from "../../authContext";

function AddGame() {
    const auth = useAuth();
    const updateGames = useContext(GameContext);

    const [rate, setRate] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        axios.get('rates?name=ATR%20Strength%20Rate').then(
            res => {
                setRate(res.data.items[0])
            },
            err => {
                console.log(err)
            }
        )
    }, []);

    const data = {
        bet: 0,
        player1Id: "",
        player2Id: "",
        ratesId: [],
    }

    const [opponent, setOpponent] = useState("");
    const [bet, setBet] = useState(0);
    let usernamesMap = new Map(Object.entries(JSON.parse(localStorage.getItem("usernamesMap"))));

    const onSubmit = async (addToast, e) => {
        e.preventDefault();
        setLoading(true);

        data.bet = parseInt(bet)
        data.player1Id = auth.user.id
        data.player2Id = usernamesMap.get(opponent)
        data.ratesId.push(rate.id)

        await axios.post('games', data,).then(
            res => {
                setLoading(false);
                if (res.status === 201) {
                    addToast({text: "Successfully created a new game", type: "success"});
                    setIsModal(false);
                    updateGames();
                } else {
                    setError("An error occurred while trying to save the game");
                }
            }
        ).catch(
            err => {
                setError("An error occurred while trying to save the game");
                setLoading(false);
            })
    };

    const modalShow = (v) => setIsModal(v);

    return (
        <ToasterContext.Consumer>
            {({addToast}) => (
                <>
                    <div>
                        <Button onClick={modalShow.bind(this, true)}>
                            Add game
                        </Button>
                    </div>
                    <AddGameModal
                        title="Add new game"
                        show={isModal}
                        close={modalShow.bind(this, false)}
                    >
                        <GameForm
                            {...{loading, opponent: opponent, setOpponent: setOpponent, bet: bet, setBet: setBet, error}}
                            onSubmit={onSubmit.bind(this, addToast)}
                        />
                    </AddGameModal>
                </>
            )}
        </ToasterContext.Consumer>
    );
}

export default AddGame;

import {useEffect, useState} from 'react';
import Button from "../../ui/core/Button";
import {Message} from "../../ui/core";
import styled from "styled-components";


const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const PlayersContainer = styled.div`
  font-size: 1rem;
  display: flex;
  flex-direction: row;
`

const Player = styled.div`
  text-align: center;
  width: 100%;
  min-width: 50%;
`

const ScoreContainer = styled.form`
  margin-top: 5px;
  margin-bottom: 5px;
`

const SetContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 5px;
`

const SetInput = styled.input`
  font-size: 1.2rem;
  max-width: 100px;
  text-align: center;
  margin-top: 10px;
  row-gap: 10px;
`

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  gap: 10px;
  width: 100%;
`

const MessageContainer = styled.div`
  max-width: 200px;
`


function SetScoreForm({loading, onSubmit, game, score: score, setScore: setScore, error}) {
    let initFormFields = []
    if (game.game.score) {
        for (let i = 0; i < game.game.score.sets; i++) {
            initFormFields.push({player1: game.game.score.player1[i], player2: game.game.score.player2[i]})
        }
    }
    const [formFields, setFormFields] = useState(initFormFields)

    useEffect(() => {
        let scoreAux = {
            sets: 0,
            player1: [],
            player2: []
        }
        scoreAux.sets = formFields.length
        formFields.forEach(function (arrayItem) {
            scoreAux.player1.push(parseInt(arrayItem.player1));
            scoreAux.player2.push(parseInt(arrayItem.player2));
        });
        setScore(scoreAux)
    }, [])


    const handleFormChange = (event, index) => {
        let data = [...formFields];
        data[index][event.target.name] = event.target.value;
        setFormFields(data);
        let scoreAux = {
            sets: 0,
            player1: [],
            player2: []
        }
        scoreAux.sets = data.length
        data.forEach(function (arrayItem) {
            scoreAux.player1.push(parseInt(arrayItem.player1));
            scoreAux.player2.push(parseInt(arrayItem.player2));
        });
        setScore(scoreAux)
    }

    const addFields = () => {
        let object = {
            player1: 0,
            player2: 0
        }
        let data = [...formFields, object]
        if (formFields.length < 5) {
            setFormFields(data)
        }
        let scoreAux = {
            sets: 0,
            player1: [],
            player2: []
        }
        scoreAux.sets = data.length
        data.forEach(function (arrayItem) {
            scoreAux.player1.push(parseInt(arrayItem.player1));
            scoreAux.player2.push(parseInt(arrayItem.player2));
        });
        setScore(scoreAux)
    }

    const removeFields = (index) => {
        let data = [...formFields];
        data.splice(index, 1)
        setFormFields(data)
        let scoreAux = {
            sets: 0,
            player1: [],
            player2: []
        }
        scoreAux.sets = data.length
        data.forEach(function (arrayItem) {
            scoreAux.player1.push(parseInt(arrayItem.player1));
            scoreAux.player2.push(parseInt(arrayItem.player2));
        });
        setScore(scoreAux)
    }

    return (
        <div>
            <MainContainer>
                <PlayersContainer>
                    <Player>
                        {game.game.player1.username}
                    </Player>
                    <Player>
                        {game.game.player2.username}
                    </Player>
                </PlayersContainer>
                <ScoreContainer onSubmit={onSubmit}>
                    {formFields.map((form, index) => {
                        return (
                            <SetContainer key={index}>
                                <SetInput
                                    type='number'
                                    min='0'
                                    name='player1'
                                    onChange={event => handleFormChange(event, index)}
                                    value={form.player1}
                                />
                                <SetInput
                                    type='number'
                                    min='0'
                                    name='player2'
                                    onChange={event => handleFormChange(event, index)}
                                    value={form.player2}
                                />
                            </SetContainer>
                        )
                    })}
                    <ButtonsContainer>
                        <Button width="60px" bg="green" type="button" onClick={addFields}>Add set</Button>
                        <Button width="60px" bg="red" type="button" onClick={() => removeFields(-1)}>Erase set</Button>
                        <Button loading={loading} type="submit">
                            Save
                        </Button>
                    </ButtonsContainer>
                </ScoreContainer>
            </MainContainer>
            <MessageContainer>
                <Message text={error} type="error"/>
            </MessageContainer>
        </div>
    );
}

export default SetScoreForm;
import axios from "axios";
import Cookies from 'universal-cookie'
import {API_URL} from "../constants";

const cookies = new Cookies();
axios.defaults.baseURL = API_URL

let refresh = false;

axios.interceptors.response.use(resp => resp, async error => {
    if (error.response.status === 401 && !refresh) {
        refresh = true;
        const response = await axios.post('refresh', {},
            {headers: {'Authorization': 'Bearer ' + cookies.get('refreshToken')}})

        if (response.status === 200) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.accessToken;
            cookies.set('refreshToken', response.data.refreshToken, {path: '/'});
            axios.get('users').then(
                res => {
                    const usersMap = new Map();
                    const usernamesMap = new Map()

                    res.data.items.forEach((element) => {
                        usersMap.set(element.id, element.username);
                        usernamesMap.set(element.username, element.id)

                    });
                    localStorage.setItem('usersMap', JSON.stringify(Object.fromEntries(usersMap)))
                    localStorage.setItem('usernamesMap', JSON.stringify(Object.fromEntries(usernamesMap)))
                },
                err => {
                    console.log(err)
                }
            )
            return axios(error.config);
        }
    }
    console.log(error.response.data.error.details.message)
    refresh = false;
    return error;
});


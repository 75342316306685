import RequestedGames from "../components/games/RequestedGames";
import UpcomingGames from "../components/games/UpcomingGames";
import CompletedGames from "../components/games/CompletedGames";
import React, {useState} from "react";
import styled from "styled-components";

const TabContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 20px;
  font-size: 0.8rem;
  color: #042a38;
`;

const Tab = styled.div`
  flex: 1;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  border: 1px solid #042a38;
  background-color: ${props => (props.selected ? "#042a38" : "#fff")};
  color: ${props => (props.selected ? "#fff" : "#042a38")};
`;

const Content = styled.div`
`;

const TabMenu = () => {
    const [selectedTab, setSelectedTab] = useState("Requested");

    return (
        <div>
            <TabContainer>
                <Tab
                    selected={selectedTab === "Requested"}
                    onClick={() => setSelectedTab("Requested")}
                >
                    Requested
                </Tab>
                <Tab
                    selected={selectedTab === "Upcoming"}
                    onClick={() => setSelectedTab("Upcoming")}
                >
                    Upcoming
                </Tab>
                <Tab
                    selected={selectedTab === "Completed"}
                    onClick={() => setSelectedTab("Completed")}
                >
                    Completed
                </Tab>
            </TabContainer>
            <Content>
                {selectedTab === "Requested" && <RequestedGames/>}
                {selectedTab === "Upcoming" && <UpcomingGames/>}
                {selectedTab === "Completed" && <CompletedGames/>}
            </Content>
        </div>
    );
};

export default TabMenu;

import {ToasterContext} from "../../ui/toasterCtx/ToasterContext";
import SetInfoForm from "./SetInfoForm";
import axios from "axios";
import SetGameInfoModal from "../../ui/SetGameInfoModal";
import styled from "styled-components";
import {GameContext} from "./GameContext";
import {useState, useContext} from "react";

const ActionButton = styled.button`
  & {
    background-color: var(--color-secondary-light);
    color: white;
    border-radius: 10px;
    border: 0px;
    padding: 5px;
    width: 30%;
    max-width: 80px;
  }

  &:hover {
    background-color: var(--color-secondary-light);
    opacity: 0.5;
  }
`


function SetInfo(props) {
    const updateGames = useContext(GameContext);

    const [loading, setLoading] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [error, setError] = useState(null);

    const infoObj = {
        location: "",
        surface: "",
        date: "",
    };

    const data = {
        location: "",
        surface: "",
        date: [],
    }

    const [surface, setSurface] = useState('')
    const [info, setInfo] = useState({...infoObj});

    const onSubmit = async (addToast, e) => {
        e.preventDefault();
        setLoading(true);

        data.location = info.location
        data.date = info.date
        data.surface = surface

        await axios.post('games/' + props.game.id + ':setInfo', data,).then(
            res => {
                setLoading(false);
                if (res.status === 200) {
                    addToast({text: "Successfully saving game info", type: "success"});
                    setIsModal(false);
                    updateGames();
                } else {
                    setError("An error occured while trying to save the info");
                }
            }
        ).catch(
            err => {
                setError("An error occured while trying to save the game info");
                setLoading(false);
            })
    };


    const modalShow = (v) => setIsModal(v);

    return (
        <ToasterContext.Consumer>
            {({addToast}) => (
                <>
                    <ActionButton onClick={modalShow.bind(this, true)}>
                        Set Info
                    </ActionButton>
                    <SetGameInfoModal
                        title="Set Game Info"
                        show={isModal}
                        close={modalShow.bind(this, false)}
                    >
                        <SetInfoForm
                            {...{loading, info: info, setInfo: setInfo, surface: surface, setSurface: setSurface, error}}
                            onSubmit={onSubmit.bind(this, addToast)}
                        />
                    </SetGameInfoModal>
                </>
            )}
        </ToasterContext.Consumer>
    );
}

export default SetInfo;

import Page from '../ui/Page';
import React, {useEffect, useState} from "react";
import GameItem from "../components/games/GameItem";
import axios from "axios";
import AddGame from "../components/games/AddGame";
import {useAuth} from "../authContext";
import {GameContext} from "../components/games/GameContext";
import styled from "styled-components";
import GameRequestedItem from "../components/games/GameRequestedItem";
import TabMenu from "./TabMenu";


function Games() {
    return (
        <Page title="Games" className="games-list">
            <TabMenu/>
        </Page>
    );
}

export default Games;
import Page from '../ui/Page';
import React, {useEffect, useState} from "react";
import axios from "axios";
import Transfer from "../components/wallet/Transfer";
import {UpdateBalanceContext} from "../components/wallet/UpdateBalanceContext";
import MovementsTable from "../components/wallet/MovementsTable";

function Wallet() {
    const [balance, setBalance] = useState([]);

    const updateBalance = () => {
        axios.get('balances').then(
            res => {
                setBalance(res.data)
            },
            err => {
                console.log(err)
            }
        )
    }

    useEffect(() => {
        updateBalance();
    }, [])

    return (
        <Page title="Wallet" className="wallet">
            <UpdateBalanceContext.Provider value={updateBalance}>
                <>
                    <p>Total available: {balance.totalAvailable}</p>
                    <p>Balance on hold: {balance.balanceOnHold}</p>
                    <p>Total balance: {balance.totalBalance}</p>
                    <Transfer/>
                    <MovementsTable></MovementsTable>
                </>
            </UpdateBalanceContext.Provider>
        </Page>
    );
}

export default Wallet;
import {useContext, useState} from "react";
import {ToasterContext} from "../../ui/toasterCtx/ToasterContext";
import axios from "axios";
import SetGameScoreModal from "../../ui/SetGameScoreModal";
import styled from "styled-components";
import {GameContext} from "./GameContext";
import SetScoreForm from "./SetScoreForm";

const ActionButton = styled.button`
  & {
    background-color: var(--color-secondary-light);
    color: white;
    border-radius: 10px;
    border: 0px;
    padding: 5px;
    width: 30%;
    max-width: 80px;
  }

  &:hover {
    background-color: var(--color-secondary-light);
    opacity: 0.5;
  }
`

function SetScore(game) {

    const updateGames = useContext(GameContext);
    const [loading, setLoading] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [error, setError] = useState(null);

    const scoreObj = {
        sets: 0,
        player1: [],
        player2: []
    }

    const [score, setScore] = useState({...scoreObj});

    const onSubmit = async (addToast, e) => {
        e.preventDefault();
        setLoading(true);

        await axios.post('games/' + game.game.id + ':setScore', score,).then(
            res => {
                setLoading(false);
                if (res.status === 200) {
                    addToast({text: "Successfully saving a game score", type: "success"});
                    setIsModal(false);
                    updateGames();
                } else {
                    setError("An error occured while trying to save the score");
                }
            }
        ).catch(
            err => {
                setError("An error occured while trying to save the score");
                setLoading(false);
            })
    };

    const modalShow = (v) => setIsModal(v);

    return (
        <ToasterContext.Consumer>
            {({addToast}) => (
                <>
                    <ActionButton onClick={modalShow.bind(this, true)}>
                        Set Score
                    </ActionButton>
                    <SetGameScoreModal
                        title="Set Game Score"
                        show={isModal}
                        close={modalShow.bind(this, false)}
                    >
                        <SetScoreForm
                            {...{loading, game, score: score, setScore: setScore, error}}
                            onSubmit={onSubmit.bind(this, addToast)}
                        />
                    </SetGameScoreModal>
                </>
            )}
        </ToasterContext.Consumer>
    );
}

export default SetScore;

import {Button, Field, Message} from "../../ui/core";
import Select from "react-select";

const options = [
    {value: 'ARTIFICIAL_GRASS', label: 'Artificial grass'},
    {value: 'GRASS', label: 'Grass'},
    {value: 'HARD', label: 'Hard'},
    {value: 'CLAY', label: 'Clay'},
]

function SetInfoForm({
                         loading,
                         onSubmit,
                         info: info,
                         error,
                         setInfo: setInfo,
                         surface: surface,
                         setSurface: setSurface
                     }) {

    const onChange = (e) => {
        setInfo({
            ...info,
            [e.target.name]: e.target.value,
        });
    };
    return (
        <form onSubmit={onSubmit}>
            <Field labelText="Location" id="info-title">
                <input
                    type="text"
                    value={info.location}
                    onChange={onChange}
                    name="location"
                    id="info-title"
                />
            </Field>
            <Field labelText="Surface" id="info-pages">
                <Select
                    options={options} styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                    }),
                }}
                    onChange={(value) => setSurface(value.value)}
                    value={surface.value}
                    placeholder="Select surface"
                    name="surface"
                />
            </Field>
            <Field labelText="Date" id="info-publish-date">
                <input
                    type="datetime-local"
                    value={info.date}
                    onChange={onChange}
                    name="date"
                    id="info-publish-date"
                />
            </Field>
            <div>
                <Button loading={loading} type="submit">
                    Save
                </Button>
            </div>
            <Message text={error} type="error"/>
        </form>
    );
}

export default SetInfoForm;

import {useContext, useState} from "react";
import Button from "../../ui/core/Button";
import {ToasterContext} from "../../ui/toasterCtx/ToasterContext";
import axios from "axios";
import TransferModal from "../../ui/TransferModal";
import TransferForm from "./TransferForm";
import {UpdateBalanceContext} from "./UpdateBalanceContext";

function Transfer() {
    const updateBalance = useContext(UpdateBalanceContext);

    const [loading, setLoading] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [error, setError] = useState(null);

    const data = {
        to: "",
        amount: 0,
    }

    const [to, setTo] = useState("");
    const [amount, setAmount] = useState(0);
    let usernamesMap = new Map(Object.entries(JSON.parse(localStorage.getItem("usernamesMap"))));

    const onSubmit = async (addToast, e) => {
        e.preventDefault();
        setLoading(true);

        data.amount = parseInt(amount)
        data.to = usernamesMap.get(to)

        await axios.post('balances/transfer', data,).then(
            res => {
                setLoading(false);
                if (res.status === 200) {
                    addToast({text: "Transfer submitted successfully", type: "success"});
                    setIsModal(false);
                    updateBalance();
                } else {
                    setError("An error occurred while trying to perform the transfer");
                }
            }
        ).catch(
            err => {
                setError("An error occurred while trying to perform the transfer");
                setLoading(false);
            })
    };

    const modalShow = (v) => setIsModal(v);

    return (
        <ToasterContext.Consumer>
            {({addToast}) => (
                <>
                    <div>
                        <Button onClick={modalShow.bind(this, true)}>
                            Transfer
                        </Button>
                    </div>
                    <TransferModal
                        title="Transfer"
                        show={isModal}
                        close={modalShow.bind(this, false)}
                    >
                        <TransferForm
                            {...{loading, to: to, setTo: setTo, amount: amount, setAmount: setAmount, error}}
                            onSubmit={onSubmit.bind(this, addToast)}
                        />
                    </TransferModal>
                </>
            )}
        </ToasterContext.Consumer>
    );
}

export default Transfer;

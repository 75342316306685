import {useContext, useState} from "react";
import Button from "../../ui/core/Button";
import {ToasterContext} from "../../ui/toasterCtx/ToasterContext";
import axios from "axios";
import styled from "styled-components";
import {GameContext} from "./GameContext";

const ActionButton = styled.button`
  & {
    background-color: #c4100a;
    color: white;
    border-radius: 10px;
    border: 0px;
    padding: 5px;
    width: 30%;
    max-width: 80px;
  }

  &:hover {
    background-color: #c4100a;
    opacity: 0.5;
  }
`

function CancelGame(game, handleReload) {

    const updateGames = useContext(GameContext);
    const [loading, setLoading] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [error, setError] = useState(null);

    const onSubmit = async (addToast, e) => {
        e.preventDefault();
        setLoading(true);
        await axios.post('games/' + game.game.id + ':cancel').then(
            res => {
                setLoading(false);
                if (res.status === 200) {
                    addToast({text: "Successfully game cancelled", type: "success"});
                    setIsModal(false);
                    updateGames();
                } else {
                    setError("An error occurred while trying to cancel the game");
                }
            }
        ).catch(
            err => {
                setError("An error occurred while trying to cancel the game");
                setLoading(false);
            })
    };

    return (
        <ToasterContext.Consumer>
            {({addToast}) => (
                <>
                    <ActionButton onClick={onSubmit.bind(this, addToast)}>
                        Cancel
                    </ActionButton>
                </>
            )}
        </ToasterContext.Consumer>
    );
}

export default CancelGame;

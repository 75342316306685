import React from 'react';
import styled from 'styled-components';

const Text = styled.div`
  font-size: 0.6rem;
  display: flex;
  flex-direction: row;
  text-align: center;
  vertical-align: center;
  font-weight: bold;

  div.L {
    color: white;
    background-color: red;
    padding: 3px;
    margin: 1px;
    border-radius: 5px;
    width: 20px;
  }

  div.W {
    color: white;
    background-color: green;
    padding: 3px;
    margin: 1px;
    border-radius: 5px;
    width: 20px;
  }
`;

const PlayerForm = ({text}) => {

    const newText = text.split('').map((char, index) => {
        if (char === 'W') {
            return <div key={index} className="W">{char}</div>;
        }
        if (char === 'L') {
            return <div key={index} className="L">{char}</div>;
        }
    });

    return (
            <Text>
                {newText}
            </Text>
    );
};

export default PlayerForm;
import {createContext, useState, useEffect, useContext} from "react";
import axios from "axios"
import Cookies from 'universal-cookie'

const cookies = new Cookies();
const authContext = createContext();

const useAuth = () => {
    return useContext(authContext);
};

const AuthProvider = ({children}) => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        const err = axios.get('me')
            .then((res) => {
                if (!res.status){
                    setUser(false)
                }
                setUser(res.data)
            });
    }, []);

    const signIn = (username, password) => {
        return new Promise(async (resolve, reject) => {
            try {
                const data = {username, password};
                let response = await axios.post('login', data);
                cookies.set('refreshToken', response.data.refreshToken, {path: '/'});
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.accessToken;
                response = await axios.get('me');
                setUser(response.data);
                resolve(response.data);
            } catch (e) {
                reject(e);
            }
        });
    };
    const signOut = () => {
        return new Promise(async (resolve, reject) => {
            try {
                cookies.remove('refreshToken');
                setUser(false);
                resolve();
            } catch (e) {
                reject(e);
            }
        });
    };
    return (
        <authContext.Provider value={{user, signIn, signOut}}>
            {children}
        </authContext.Provider>
    );
};

export {AuthProvider, useAuth};

import React, {useEffect, useState} from "react";
import GameItem from "./GameItem";
import axios from "axios";
import {useAuth} from "../../authContext";
import {GameContext} from "./GameContext";
import styled from "styled-components";

const GamesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
`

function UpcomingGames() {

    const auth = useAuth();
    const [pendingGames, setPendingGames] = useState([]);

    const updateGames = () => {
        axios.get('games?state=ACCEPTED&player=' + auth.user.id).then(
            res => {
                setPendingGames(res.data.items)
            },
            err => {
                console.log(err)
            }
        )
    }

    useEffect(() => {
        updateGames();
    }, [])

    return (
        <GameContext.Provider value={updateGames}>
            <GamesContainer>
                {pendingGames.map((game) => (
                    <GameItem game={game}/>
                ))}
            </GamesContainer>
        </GameContext.Provider>
    );
}

export default UpcomingGames;
import React, {useEffect, useState} from "react";
import axios from "axios";
import AddGame from "./AddGame";
import {useAuth} from "../../authContext";
import {GameContext} from "./GameContext";
import styled from "styled-components";
import GameRequestedItem from "./GameRequestedItem";

const GamesContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
`

function RequestedGames() {

    const auth = useAuth();
    const [requestedGames, setRequestedGames] = useState([]);

    const updateGames = () => {
        axios.get('games?state=CREATED&player=' + auth.user.id).then(
            res => {
                setRequestedGames(res.data.items)
            },
            err => {
                console.log(err)
            }
        )
    }

    useEffect(() => {
        updateGames();
    }, [])

    return (
        <GameContext.Provider value={updateGames}>
            <AddGame/>
            <GamesContainer>
                {requestedGames.map((game) => (
                    <GameRequestedItem game={game}/>
                ))}
            </GamesContainer>
        </GameContext.Provider>
    );
}

export default RequestedGames;
import styled from "styled-components";
import NavLink from "./NavLink";
import {breakpoints as bp} from '../../../GlobalStyle';

const LinksGroup = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: ${p => Number(!p.compact)};
  padding: 24px 0 14px 0;
  margin-right: 2px;
  overflow: hidden;
  overflow-y: auto;
  background-color: rgba(var(--color-secondary-rgb), 0.1);
  transition: flex-grow 0.3s cubic-bezier(0.4, 0, 1, 1);
  ::-webkit-scrollbar {
    width: 4px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.4);
    border-radius: 4px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.5);
  }
  @media(max-width: ${bp.desktop}) {
      flex-grow: 1;
  }
`;

const DenseNavLinks = styled(NavLink)`
  && {
    box-shadow: none;
    min-height: 36px;
  }
`;

const links = [
  {
    to: "/home",
    icon: "fas fa-home",
    label: "Home",
  },
  {
    to: "/games",
    icon: "fas fa-calendar",
    label: "My Games",
  },
  {
    to: "/ranking",
    icon: "fas fa-medal",
    label: "Ranking",
  },
  {
    to: "/wallet",
    icon: "fas fa-credit-card",
    label: "Wallet",
  },
  // {
  //   to: "/tournaments",
  //   icon: "fas fa-trophy",
  //   label: "Tournaments",
  // },
  // {
  //   to: "/social",
  //   icon: "fas fa-users",
  //   label: "Social",
  // },
  // {
  //   to: "/news",
  //   icon: "fas fa-newspaper",
  //   label: "News",
  // },
  // {
  //   to: "/draft",
  //   icon: "fas fa-robot",
  //   label: "Draft",
  // },
];

function NavLinksGroup(props) {
  return (
    <LinksGroup {...props}>
      {links.map((l) => (
        <DenseNavLinks
          compact={props.compact}
          key={l.to}
          to={l.to}
          iconClassName={l.icon}
          label={l.label}
        />
      ))}
    </LinksGroup>
  );
}

export default NavLinksGroup;

import styled from 'styled-components';
import '../../public/atr-logo.svg'
import {useHistory, useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import {useAuth} from "../authContext";
import axios from "axios";
import {Message} from "../ui/core";
import {useParams} from "react-router-dom";

const LogoStyle = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  padding-top: 10%;
`

const FormStyle = styled.div`
  max-width: 500px;
  min-width: 300px;
  max-height: 200px;
  width: 30%;
  height: 30%;
  margin: 40px auto;
  background-color: #FFFFFF;
  border-radius: 25px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
  max-width: 100%;
`

const RowInput = styled.input`
  & {
    width: 80%;
    box-sizing: border-box;
    border: none;
    font-size: 1rem;
    padding-left: 1.5rem;
    padding-bottom: 1rem;
    box-shadow: inset 0px -3px 0px 0px rgba(9, 8, 8, 0.2);
    transition: box-shadow 0.2s ease-in;
    -webkit-appearance: none;
    border-radius: 0px;
  }

  &:focus {
    box-shadow: inset 0px -3px 0px 0px var(--color-primary);
    outline: none;
  }

  &::-webkit-input-placeholder {
    opacity: 1;
    transition: opacity 0.25s ease-out;
    color: rgba(9, 5, 5, 0.34);
  }

  &:hover::-webkit-input-placeholder,
  &:focus::-webkit-input-placeholder {
    opacity: 0;
  }
`

const RowLabel = styled.label`
  align-self: start;
  padding-left: 4.5rem;
  padding-bottom: 0.5rem;
  color: #555;
  font-size: 1rem;
`

const RowButton = styled.button`
  & {
    border-radius: 25px;
    width: 80%;
    height: 40px;
    font-size: 1.3rem;
    color: white;
    font-weight: 700;
    background: linear-gradient(90deg, var(--color-secondary) 0%, var(--color-secondary-light) 100%);
    border: 2px solid;
    border-color: var(--color-primary);
    cursor: pointer;
    transition: opacity 0.25s ease-out;
  }

  &:hover {
    opacity: 0.8;
  }
`

export const ResetPasswordForm = () => {
    const history = useHistory();
    const location = useLocation();
    const [newPassword, setNewPassword] = useState({
        newPassword: "",
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const auth = useAuth();
    const params = useParams();


    useEffect(() => {
        if (auth.user) history.replace(location.state ? location.state.from : '/');
    }, [auth.user])

    const onChange = (e) => {
        setNewPassword((prevCreds) => ({
            ...prevCreds, [e.target.name]: e.target.value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        await axios.post('resetPassword', newPassword, {headers: {'Authorization': 'Bearer ' + params.token}}
        ).then(async (resp) => {
            if (resp.status === 200) {
                setSuccess("Password updated correctly")
                setLoading(false);
            } else {
                setError("Some error occurred updating password");
            }
        }).catch(() => {
            setError("Error sending email");
            setLoading(false);
        });
    }

    return (
        <>
            <Message text={error} type="error"/>
            <Message text={success} type="success"/>
            <FormStyle>
                <form onSubmit={handleSubmit}>
                    <Form newPassword={newPassword} onChange={onChange}/>
                </form>
            </FormStyle>
        </>)
}

const FormHeader = () => (
    <a href={'/'}>
        <LogoStyle src={'atr-logo.svg'}/>
    </a>);


const Form = props => (<div>
    <FormInput value={props.newPassword.newPassword} onChange={props.onChange} description="New Password"
               placeholder="Enter your new password"
               type="password" name="newPassword"/>
    <FormButton title="Submit"/>
</div>);

const FormButton = props => (<Row>
    <RowButton type="submit">{props.title}</RowButton>
</Row>);

const FormInput = props => (<Row>
    <RowLabel>{props.description}</RowLabel>
    <RowInput type={props.type}
              placeholder={props.placeholder}
              value={props.value}
              onChange={props.onChange}
              name={props.name}
              id={props.name}
    />
</Row>);
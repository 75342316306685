import Page from '../ui/Page';
import React, {useEffect, useState} from "react";
import RankingTable from "../components/rankings/RankingTable";

function Ranking() {
    return (
        <Page title="Ranking" className="rankings-list">
            <RankingTable/>
        </Page>
    );
}

export default Ranking;
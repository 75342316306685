import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import Home from "./pages/Home";
import Ranking from "./pages/Ranking"
import "./styles.css";
import {ToasterProvider} from "./ui/toasterCtx/ToasterContext";
import {AuthProvider} from "./authContext";
import PrivateRoute from "./PrivateRoute";
import GlobalStyle from './GlobalStyle';
import Layout from './components/layout/Layout';
import {LoginForm} from "./pages/Login";
import {RegisterForm} from "./pages/Register";
import Wallet from "./pages/Wallet";
import Games from "./pages/Games";
import RankingTable from "./components/rankings/RankingTable";
import {ForgotPasswordForm} from "./pages/ForgotPassword";
import {ResetPasswordForm} from "./pages/ResetPassword";

function App() {
    return (
        <AuthProvider>
            <Router>
                <GlobalStyle/>
                <ToasterProvider>
                    <Layout>
                        <Switch>
                            <PrivateRoute path="/" exact>
                                <Redirect to="/home"/>
                            </PrivateRoute>
                            <PrivateRoute path="/home" exact>
                                <Home/>
                            </PrivateRoute>
                            <PrivateRoute path="/games" exact>
                                <Games/>
                            </PrivateRoute>
                            <PrivateRoute path="/ranking" exact>
                                <Ranking/>
                            </PrivateRoute>
                            <PrivateRoute path="/wallet" exact>
                                <Wallet/>
                            </PrivateRoute>
                            {/*<PrivateRoute path="/draft" exact>*/}
                            {/*    <Draft/>*/}
                            {/*</PrivateRoute>*/}
                            <Route path="/login">
                                <LoginForm/>
                            </Route>
                            <Route path="/register">
                                <RegisterForm/>
                            </Route>
                            <Route path="/forgotpassword">
                                <ForgotPasswordForm/>
                            </Route>
                            <Route path="/resetpassword/:token">
                                <ResetPasswordForm/>
                            </Route>
                        </Switch>
                    </Layout>
                </ToasterProvider>
            </Router>
        </AuthProvider>
    );
}

export default App;
